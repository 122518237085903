import React, { Component } from 'react';
import Intro from './views/Intro/Intro'
import logo from './assets/Logo.svg'
import './App.scss';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      connection: true,
      chat: false,
      done: false
    }
    this.data = {}
  }

  chat (data) {
    Object.assign(this.data, data)
    this.setState({chat: true})
  }

  render () {
    return (
      <div className="App">
        { !this.state.connection ?
          <div className="load-screen">
            <img src={logo} className="App-logo" alt="logo" />
            <div className="load-error hidden">
              Seltsam... Keine Verbindung zum Backend möglich.
              <span className="instructions">
                Vielleicht wird gerade ein Update installiert. Versuchen Sie es in wenigen Minuten erneut.<br />
                Hilft alles nichts? <a href="tel:+4965616994420" className="support-button">Support</a>
              </span>
            </div>
          </div>
        : null}
        { this.state.connection ?
          <Intro />
        : null }
        <NotificationContainer/>
      </div>
    );
  }
}

export default App;
