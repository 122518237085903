import { Component } from 'react';
import './intro.scss';
import Stack from '../../components/Stack/Stack';

class Intro extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stacks: []
    }
    this.cards = ["club_7", "diamond_7", "heart_7", "spade_7", "club_8", "diamond_8", "heart_8", "spade_8", "club_9", "diamond_9", "heart_9", "spade_9", "club_10", "diamond_10", "heart_10", "spade_10", "club_jack", "diamond_jack", "heart_jack", "spade_jack", "club_queen", "diamond_queen", "heart_queen", "spade_queen", "club_king", "diamond_king", "heart_king", "spade_king", "club_1", "diamond_1", "heart_1", "spade_1"];
  }

  componentDidMount () {
    
  }

  addStack (name) {
    const stacks = this.state.stacks
    stacks.push({
      name,
      card: this.cards[Math.floor(Math.random() * 32)]
    })
    this.setState({stacks})
  }

  draw () {
    const stacks = []
    let high
    let player
    let card
    for (const stack of this.state.stacks) {
      stack.card = this.cards[Math.floor(Math.random() * 32)]
      const index = this.cards.indexOf(stack.card)
      if (!high || (index > high && card !== stack.card.split('_')[0])) {
        high = index
        player = stack.name
        card = stack.card.split('_')[0]
      } else if (card === stack.card.split('_')[0]) {
        player = 'Niemand'
      }
      stacks.push(stack)
    }
    this.setState({stacks, winner: player})
  }

  render () {
    let stacks = this.state.stacks.map((stack) =>
      <Stack card={stack.card} name={stack.name} />
    );

    return (
      <div className="Intro">
        <div className="adder">
          <input type="text" placeholder="Name" onKeyUp={(evt) => { if (evt.code === 'Enter') { this.addStack(document.querySelector('input').value); document.querySelector('input').value = '';  }}} />
          <button onClick={() => {this.addStack(document.querySelector('input').value); document.querySelector('input').value = '';  }}>Hinzufügen</button>
          <button onClick={() => {this.draw()}}>Ziehen</button>
          {this.state.winner} trinkt nicht!
        </div>
        {stacks}
      </div>
    );
  }
}

export default Intro;
