import { Component } from 'react';
import './stack.scss';

import club_1 from '../../assets/32/club_1.png'
import diamond_1 from '../../assets/32/diamond_1.png'
import heart_1 from '../../assets/32/heart_1.png'
import spade_1 from '../../assets/32/spade_1.png'
import club_7 from '../../assets/32/club_7.png'
import diamond_7 from '../../assets/32/diamond_7.png'
import heart_7 from '../../assets/32/heart_7.png'
import spade_7 from '../../assets/32/spade_7.png'
import club_8 from '../../assets/32/club_8.png'
import diamond_8 from '../../assets/32/diamond_8.png'
import heart_8 from '../../assets/32/heart_8.png'
import spade_8 from '../../assets/32/spade_8.png'
import club_9 from '../../assets/32/club_9.png'
import diamond_9 from '../../assets/32/diamond_9.png'
import heart_9 from '../../assets/32/heart_9.png'
import spade_9 from '../../assets/32/spade_9.png'
import club_10 from '../../assets/32/club_10.png'
import diamond_10 from '../../assets/32/diamond_10.png'
import heart_10 from '../../assets/32/heart_10.png'
import spade_10 from '../../assets/32/spade_10.png'
import club_jack from '../../assets/32/club_jack.png'
import diamond_jack from '../../assets/32/diamond_jack.png'
import heart_jack from '../../assets/32/heart_jack.png'
import spade_jack from '../../assets/32/spade_jack.png'
import club_queen from '../../assets/32/club_queen.png'
import diamond_queen from '../../assets/32/diamond_queen.png'
import heart_queen from '../../assets/32/heart_queen.png'
import spade_queen from '../../assets/32/spade_queen.png'
import club_king from '../../assets/32/club_king.png'
import diamond_king from '../../assets/32/diamond_king.png'
import heart_king from '../../assets/32/heart_king.png'
import spade_king from '../../assets/32/spade_king.png'

class Stack extends Component {
  constructor (props) {
    super(props);
    this.state = {}
    this.images = {
      club_1,
      diamond_1, 
      heart_1, 
      spade_1, 
      club_7, 
      diamond_7, 
      heart_7, 
      spade_7, 
      club_8, 
      diamond_8, 
      heart_8, 
      spade_8, 
      club_9, 
      diamond_9, 
      heart_9, 
      spade_9, 
      club_10, 
      diamond_10, 
      heart_10, 
      spade_10, 
      club_jack, 
      diamond_jack, 
      heart_jack, 
      spade_jack, 
      club_queen, 
      diamond_queen, 
      heart_queen, 
      spade_queen, 
      club_king, 
      diamond_king, 
      heart_king, 
      spade_king, 
    }
  }

  componentDidMount () {
  }

  render () {
      return (
        <div className="Stack">
          <span className="name">{this.props.name}</span>
          <img src={this.images[this.props.card]} />
        </div>
      );
  }
}

export default Stack;
